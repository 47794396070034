import React from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import { createStyles, Navbar, ScrollArea } from '@mantine/core'
import { DrawerLinks } from './links'
import {
    IconAdjustmentsAlt,
    IconBook2,
    IconBuildingSkyscraper,
    IconCar,
    IconDiscount2,
    IconNotebook,
    IconPalette,
} from '@tabler/icons-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MantineProvider } from '@/theme'

interface DrawerProps {
    setOpen: ReactSetState<boolean>
    open: boolean
}

const useStyles = createStyles((theme, { open }: { open: boolean }) => ({
    navbar: {
        backgroundColor: theme.white,
        paddingBottom: 0,
        width: 294,
        position: 'fixed',
        top: '0px',
        left: open ? 0 : -300,
        padding: '102px 0px 0px 8px',
        zIndex: 1,
        transition: '.1s all ease',

        '.mantine-ScrollArea-scrollbar': {
            cursor: 'pointer',
        },

        '.mantine-ScrollArea-thumb': {
            background: theme.colors.gray[4],

            '&:hover': {
                background: theme.colors.gray[5],
            },
        },
    },

    links: {
        padding: '8px 0',
    },
}))

const Icons = {
    references: IconBook2,
    vehicle: IconCar,
    universal: IconAdjustmentsAlt,
    colors: IconPalette,
    countries: IconBuildingSkyscraper,
    journal: IconNotebook,
}

export const Drawer: React.FC<DrawerProps> = observer(({ setOpen, open, ...rest }) => {
    const location = useLocation()
    const { classes } = useStyles({ open })

    return (
        <MantineProvider>
            <Navbar height={'100%'} className={classes.navbar} {...rest}>
                <Navbar.Section grow className={classes.links} component={ScrollArea} scrollbarSize={10}>
                    {store.app.schemes.map(
                        (scheme, i) =>
                            scheme.visible !== false && (
                                <DrawerLinks
                                    key={i}
                                    icon={Icons[scheme.id] || IconNotebook}
                                    title={scheme.title}
                                    initialOpened={location.pathname.includes(`/analytics/${scheme.id}`)}
                                    items={scheme.items.map((item) => ({
                                        ...item,
                                        path: `/analytics/${scheme.id}${item.path}`,
                                    }))}
                                />
                            )
                    )}
                    <DrawerLinks
                        icon={IconDiscount2}
                        initialOpened={location.pathname.includes(`/analytics/residual`)}
                        title='Остаточные стоимости'
                        path={'/analytics/residual'}
                    />
                </Navbar.Section>
            </Navbar>
        </MantineProvider>
    )
})
