import { AnalyticsSchemeResponse } from '.'

export const AnalyticsSchemeMock: ApiResponse<AnalyticsSchemeResponse> = {
    data: {
        status: 'ok',
        payload: [
            {
                id: 'references',
                title: 'Основные справочники',
                items: [
                    {
                        id: 'brand_id',
                        path: '/brands',
                        api: '/brands',
                        title: 'Марки',
                    },
                    {
                        id: 'model_id',
                        path: '/models',
                        api: '/models',
                        title: 'Модели',
                    },
                    {
                        id: 'modification_id',
                        path: '/modifications',
                        api: '/modifications',
                        title: 'Модификации',
                    },
                    {
                        id: 'complectations',
                        path: '/complectations',
                        api: '/complectations',
                        title: 'Комплектации',
                        report: 'complectations',
                        items: [
                            {
                                id: 'complectations_specific_color',
                                path: '/specific-body-colors',
                                api: '/specific-body-colors',
                                title: 'Цвета',
                                passport: 'specific_color_id',
                            },
                            {
                                id: 'complectations_specific_option',
                                path: '/specific-options',
                                api: '/specific-options',
                                title: 'Специфичные опции',
                                passport: 'specific_option_id',
                            },
                            {
                                id: 'complectations_option_package',
                                path: '/option-packages',
                                api: '/option-packages',
                                title: 'Пакеты опций',
                                passport: 'option_package_id',
                            },
                            {
                                id: 'complectations_additional_param',
                                path: '/additional-params',
                                api: '/additional-params',
                                title: 'Дополнительные параметры',
                                passport: 'additional_param_id',
                            },
                        ],
                    },
                ],
            },
            {
                id: 'vehicle',
                title: 'Технические характеристики',
                items: [
                    {
                        id: 'vehicle_type_id',
                        path: '/vehicle-types',
                        api: '/vehicle-types',
                        title: 'Типы ТС',
                    },
                    {
                        id: 'body_type_id',
                        path: '/body-types',
                        api: '/body-types',
                        title: 'Типы кузова',
                    },
                    {
                        id: 'drive_type_id',
                        path: '/drive-types',
                        api: '/drive-types',
                        title: 'Типы привода',
                    },
                    {
                        id: 'engine_type_id',
                        path: '/engine-types',
                        api: '/engine-types',
                        title: 'Типы двигателя',
                    },
                    {
                        id: 'transmission_type_id',
                        path: '/transmission-types',
                        api: '/transmission-types',
                        title: 'Типы КПП',
                    },
                    {
                        id: 'power_steering_type_id',
                        path: '/power-steering-types',
                        api: '/power-steering-types',
                        title: 'Типы усилителя руля',
                    },
                    {
                        id: 'generation_id',
                        path: '/model-generations',
                        api: '/model-generations',
                        title: 'Поколения',
                    },
                    {
                        id: 'vehicle_category_id',
                        path: '/vehicle-categories',
                        api: '/vehicle-categories',
                        title: 'Категории ТС',
                    },
                    {
                        id: 'wheel_layout_id',
                        path: '/wheel-layouts',
                        api: '/wheel-layouts',
                        title: 'Колесная формула',
                        visible: false,
                    },
                    {
                        id: 'final_vehicle_brand_id',
                        path: '/final-vehicle-brands',
                        api: '/final-vehicle-brands',
                        title: 'Марка конечного ТС',
                        visible: false,
                    },
                    {
                        id: 'final_vehicle_model_id',
                        path: '/final-vehicle-models',
                        api: '/final-vehicle-models',
                        title: 'Модель конечного ТС',
                        visible: false,
                    },
                ],
            },
            {
                id: 'universal',
                title: 'Опции',
                items: [
                    {
                        id: 'option_group_id',
                        path: '/option-groups',
                        api: '/option-groups',
                        title: 'Группы опций',
                    },
                    {
                        id: 'universal_option_id',
                        path: '/universal-options',
                        api: '/universal-options',
                        title: 'Универсальные опции',
                    },
                    {
                        id: 'specific_option_id',
                        path: '/specific-options',
                        items: [
                            {
                                id: 'specific_option_complectations',
                                path: '/complectations',
                                api: '/complectations',
                                title: 'Комплектации',
                                passport: 'complectations',
                            },
                        ],
                        api: '/specific-options',
                        title: 'Специфичные опции',
                    },
                    {
                        id: 'option_package_id',
                        path: '/option-packages',
                        items: [
                            {
                                id: 'option_package_complectations',
                                path: '/complectations',
                                api: '/complectations',
                                title: 'Комплектации',
                                passport: 'complectations',
                            },
                            {
                                id: 'option_package_specific-options',
                                path: '/specific-options',
                                api: '/specific-options',
                                title: 'Опции',
                                passport: 'specific-options',
                            },
                        ],
                        api: '/option-packages',
                        title: 'Пакеты опций',
                    },
                ],
            },
            {
                id: 'colors',
                title: 'Цвета',
                items: [
                    {
                        id: 'universal_color_id',
                        path: '/universal-body-colors',
                        api: '/universal-body-colors',
                        title: 'Универсальные цвета',
                    },
                    {
                        id: 'specific_color_id',
                        path: '/specific-body-colors',
                        api: '/specific-body-colors',
                        title: 'Специфичные цвета',
                    },
                ],
            },
            {
                id: 'countries',
                title: 'Страны',
                items: [
                    {
                        id: 'vtbl_origin_country_id',
                        path: '/vtbl-origin-countries',
                        api: '/vtbl-origin-countries',
                        title: 'Страны происхождения ВТБЛ',
                    },
                    {
                        id: 'manufacturer_country_id',
                        path: '/manufacturer-countries',
                        api: '/manufacturer-countries',
                        title: 'Страны производители',
                    },
                ],
            },
            {
                id: 'journal',
                title: 'Журналы',
                items: [
                    {
                        id: 'model_discounts',
                        path: '/model-discounts',
                        api: '/model-discounts',
                        title: 'Скидки',
                    },
                    {
                        id: 'discount_id',
                        path: '/discounts',
                        api: '/discounts',
                        title: 'Тип скидки',
                        visible: false,
                    },
                ],
            },
            // {
            //     id: 'others',
            //     title: 'Другое',
            //     visible: false,
            //     items: [
            //     ],
            // },
        ],
    },
}
