import { makeAutoObservable, toJS } from 'mobx'
import { service } from '@/api'
import { notifyError, notifySuccess } from '@/theme/@deprecated/components/ui'
import { store } from '..'
import { array } from '@/shared'

const characteristics = {
    'specific-options': [
        {
            id: 'name',
            path: '',
            label: 'Название опции:',
        },
        {
            id: 'brand_code',
            path: '',
            label: 'Код проиводителя:',
        },
        {
            id: 'universal_option_id',
            path: 'universal-options',
            label: 'Универсальные опции:',
        },
        {
            id: 'option_group_id',
            path: 'option-groups',
            label: 'Группа:',
        },
        {
            id: 'brand_id',
            path: 'brands',
            label: 'Марка:',
        },
        {
            id: 'description',
            path: 'brands',
            label: 'Описание опции:',
            type: 'textarea',
        },
    ],
    complectations: [
        {
            id: 'name',
            path: 'brand',
            label: 'Название комплектации:',
        },
        {
            id: 'brand_code',
            path: 'brand',
            label: 'Код комплектации:',
        },
        {
            id: 'brand_id',
            path: 'brands',
            label: 'Марка:',
        },
        {
            id: 'model_id',
            path: 'models',
            label: 'Модель:',
        },
        // {
        //     id: 'modification_id',
        //     path: 'modifications',
        //     label: 'Модификация:',
        // },
        {
            id: 'final_vehicle_brand_id',
            path: 'final-vehicle-brands',
            label: 'Марка конечного ТС:',
        },
        {
            id: 'final_vehicle_model_id',
            path: 'final-vehicle-models',
            label: 'Модель конечного ТС:',
        },
        {
            id: 'manufacturer_country_id',
            path: 'manufacturer-countries',
            label: 'Страна производитель:',
        },
        {
            id: 'model_year',
            path: 'brand',
            label: 'Модельный год:',
        },
        {
            id: 'tires_size',
            path: 'brand',
            label: 'Размерность шин:',
            type: 'string',
        },
        {
            id: 'rims_size',
            path: 'brand',
            label: 'Размер дисков:',
        },
        {
            id: 'seats_count',
            path: 'brand',
            label: 'Количество мест:',
        },
        {
            id: 'power_steering_type_id',
            path: 'power-steering-types',
            label: 'Усилитель руля:',
        },
        // {
        //     id: 'rrp_last',
        //     path: 'brand',
        //     label: 'РРЦ крайнего среза (руб.)',
        // },
        {
            id: 'rrp_current',
            path: 'brand',
            label: 'Текущая РРЦ (Руб.)',
        },
        {
            id: 'discount_trade_in',
            path: 'brand',
            label: 'Скидка за Трейд-Ин:',
        },
        {
            id: 'body_length',
            path: 'brand',
            label: 'Длина (мм):',
        },
        {
            id: 'body_width',
            path: 'brand',
            label: 'Ширина (мм):',
        },
        {
            id: 'body_height',
            path: 'brand',
            label: 'Высота (мм):',
        },
        {
            id: 'carrying_capacity',
            path: 'brand',
            label: 'Грузоподъемность (кг):',
        },
        {
            id: 'unladen_weight',
            path: 'brand',
            label: 'Масса снаряженная (кг):',
        },
        {
            id: 'full_weight',
            path: 'brand',
            label: 'Масса полная (кг):',
        },
        {
            id: 'rrp_analysts_updated_at',
            path: 'brand',
            label: 'Дата Крайнего Среза Цены Аналитиками:',
            type: 'data',
        },
        // {
        //     id: 'rrp_autostat_updated_at',
        //     path: 'brand',
        //     label: 'Дата Крайнего Среза Цены Автостат:',
        //     type: 'data',
        // },
    ],
    'option-packages': [
        {
            id: 'name',
            path: '',
            label: 'Название:',
        },
        {
            id: 'brand_code',
            path: '',
            label: 'Код производителя:',
        },
        {
            id: 'brand_id',
            path: 'brands',
            label: 'Марка:',
        },
        {
            id: 'description',
            path: '',
            label: 'Описание:',
            type: 'textarea',
        },
    ],
}

export class PassportStore {
    response = null
    data = null
    params = null
    loading = false

    constructor() {
        makeAutoObservable(this)
    }

    getData = async (req) => {
        store.app.setState({ loading: true })

        const response = await service.analytics.reference_get(req)

        store.app.setState({ loading: false })

        if (response) {
            this.setResponse(response)

            const data = characteristics[req.reference]
                .map((item) => {
                    const current = response.columns.find((column) => column.id === item.id)

                    return current
                        ? {
                              type: current.type,
                              value:
                                  ['select', 'multiSelect'].indexOf(current.type) > -1
                                      ? {
                                            selected: response.data[item.id],
                                            list: [],
                                        }
                                      : response.data[item.id].value,
                              ...item,
                          }
                        : null
                })
                .filter(Boolean)

            const getParams = (reference) => {
                switch (reference) {
                    case 'complectations':
                        return {
                            search: '',
                            'page[number]': '1',
                            'page[size]': '25',
                            'filters[brand_id]': response.data['brand_id'].id,
                            'filters[model_id]': response.data['model_id'].id,
                            'filters[modification_id]': response.data['modification_id'].id,
                            'filters[manufacturer_country_id]': response.data['manufacturer_country_id'].id,
                            'filters[power_steering_type_id]': response.data['power_steering_type_id'].id,
                        }
                    default:
                        return {
                            search: '',
                            'page[number]': '1',
                            'page[size]': '25',
                        }
                }
            }

            this.setData(data)
            this.setParams(getParams(req.reference))
        }
    }

    updateData = async (req) => {
        store.app.setState({ loading: true })

        try {
            const getValue = (data) => {
                const selected = toJS(data.value?.selected)

                switch (data.type) {
                    case 'select':
                        if (Array.isArray(selected)) {
                            return selected.length > 0 ? { value: selected } : {}
                        } else {
                            return selected ? { id: selected.id } : {}
                        }
                    case 'multiSelect':
                        return toJS(data.value.selected)
                    default:
                        return { value: data.value ? data.value : '' }
                }
            }
            let prepareData = {
                id: { ...this.response.data.id },
                ...this.data.reduce((target, key) => {
                    target[key.id] = getValue(key)

                    return target
                }, {}),
            }

            if (req.url === '/complectations') {
                prepareData = {
                    is_archival: { ...this.response.data.is_archival },
                    modification_id: { ...this.response.data.modification_id },
                    ...prepareData,
                }
            }

            await service.analytics.reference_update({ ...req, data: [prepareData] })

            notifySuccess('Запись обновлена')
        } catch (err) {
            notifyError(err.message)
            console.error(err)
        }

        store.app.setState({ loading: false })
    }

    setData = (data) => (this.data = data)

    setResponse = (data) => (this.response = data)

    setParams = (params) => (this.params = params)

    changeInputValue = (payload) => {
        let current = this.data.find((item) => item.id === payload.id)

        current.value = payload.value
    }

    changeSelectValue = (payload) => {
        const current = this.data.find((item) => item.id === payload.id)

        if (current.type === 'multiSelect') {
            const isSelected = current.value.selected.some((el) => el.id === payload.value.id)

            if (isSelected) {
                current.value.selected = current.value.selected.filter((el) => el.id !== payload.value.id)
            } else {
                current.value.selected = [...current.value.selected, payload.value]
            }
        } else {
            current.value.selected = payload.value
        }
    }

    getSelectItems = async (payload) => {
        const response = await service.analytics.reference_get({
            url: `/${payload.path}`,
            data: payload.params ? { ...this.params, ...payload.params } : { ...this.params },
        })
        const list = response.data.map((item) => ({
            id: item.id.value,
            value: item.name.value,
        }))

        this.setSelectItems(payload.id, list)
    }

    getMultiSelectItems = async (payload) => {
        this.setLoading(true)

        const response = await service.analytics.reference_get({
            url: `/${payload.path}`,
            data: payload.params ? { ...this.params, ...payload.params } : { ...this.params },
        })

        let list = response.data.map((item) => ({
            id: item.id.value,
            value: item.name.value,
        }))

        if (!(payload.params && payload.params.search !== '')) {
            list = array.uniqueByParam(
                [
                    ...payload.value.selected,
                    ...response.data.map((item) => ({
                        id: item.id.value,
                        value: item.name.value,
                    })),
                ],
                'id'
            )
        }

        this.setSelectItems(payload.id, list)
    }

    setSelectItems = (id, list) => {
        const current = this.data.find((item) => item.id === id)

        current.value.list = list

        setTimeout(() => this.setLoading(false), 500)
    }

    setLoading = (payload) => (this.loading = payload)
}
